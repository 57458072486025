import * as React from 'react';

import { Contact, PropertyTaxServices } from '../components/landing';
import { Footer, GoogleNoIndex, PageTitle } from '../components/shared';

import { HeroSectionMissedCall } from '../components/landing/HeroSectionMissedCall';

const MissedCallPage: React.FunctionComponent = () => {
  return (
    <>
      <PageTitle />
      <GoogleNoIndex />
      <HeroSectionMissedCall />
      <PropertyTaxServices />
      <Contact />
      <Footer />
    </>
  );
};

export default MissedCallPage;
