import * as React from 'react';

import { Button, Col, Container, Row, Stack } from 'react-bootstrap';
import { faFileSignature, faPhone } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LeadForm } from './LeadForm';
import { StaticImage } from 'gatsby-plugin-image';
import { useScreenInfo } from '../../utils';

export const HeroSectionMissedCall: React.FunctionComponent = () => {
  const screen = useScreenInfo();
  const [showForm, setShowForm] = React.useState(false);

  return (
    <div id="landing-hero">
      {!screen.isPhone && (
        <StaticImage
          className="bg-image"
          src="../../images/section-backgrounds/hero-image.jpeg"
          alt="hero image"
          loading="eager"
          breakpoints={[750, 1080, 1366, 1920]}
        />
      )}
      <Container fluid="lg" className="content">
        <div className="logo-header">
          <StaticImage className="logo" src="../../images/logos/ttp-logo-white.png" height={100} alt="logo image" placeholder="none" />
        </div>

        <Row>
          <Col md={8}>
            <h1>Sorry we missed you.</h1>
            <div className="mb-3">Due to the upcoming appeal deadline, we are experiencing high call volumes and longer than anticipated wait times.</div>

            {!screen.isPhone && (
              <>
                <Stack direction="horizontal" gap={3} className="mb-4">
                  <div style={{ minWidth: 100 }}>
                    <StaticImage src="../../images/icons/why-choose-us-research.png" alt="research" />
                  </div>
                  <div>
                    <h3>Research Technology</h3>
                    Our property research software applies big-data algorithms to analyze millions of data records to gather the facts we need to prepare your
                    tax protest case.
                  </div>
                </Stack>

                <Stack direction="horizontal" gap={3} className="mb-4">
                  <div style={{ minWidth: 100 }}>
                    <StaticImage src="../../images/icons/why-choose-us-support.png" alt="support" />
                  </div>
                  <div>
                    <h3>Support From Real People</h3>
                    Local, helpful professionals that work with thousands of real estate owners each year.
                  </div>
                </Stack>
              </>
            )}
          </Col>
          <Col md={4} className="pb-4">
            {!showForm && (
              <div id="next-step-parent" className="rounded shadow border">
                <div className="display-6 text-center font-bitter mt-2">Choose your next step</div>
                <hr />

                <div className="d-grid next-step-card px-2 py-3 text-center">
                  <h4 className="mb-0">
                    <FontAwesomeIcon icon={faFileSignature} size="2x" className="mb-2" />
                    <br />
                    Option 1<br />
                    Sign Up Now
                  </h4>
                  <div className="my-3">I'm ready to take back control of my property taxes.</div>
                  <Button className="text-uppercase my-0" href="/signup/?r=mc">
                    <div className="fs-6">Sign Up Now</div>
                  </Button>
                </div>
                <div className="display-6 text-center font-bitter my-3">Or</div>
                <div className="d-grid next-step-card px-2 py-3 text-center">
                  <h4 className="mb-0">
                    <FontAwesomeIcon icon={faPhone} size="2x" className="mb-2" />
                    <br />
                    Option 2<br />I have a few questions.
                  </h4>
                  <div className="my-3">I have a few questions and I would like to talk to someone before signing up.</div>
                  <Button className="text-uppercase my-0 text-nowrap fs-6" onClick={() => setShowForm(true)}>
                    Request a Call Back
                  </Button>
                </div>
              </div>
            )}

            {showForm && <LeadForm leadSource="Missed Call" title="Request a Call Back" />}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
